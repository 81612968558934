import { ethers } from 'ethers';
import React from 'react';

import {
    META_APP2_CONTRACT,
    CHAIN_ID,
    CHAIN_NAME,
    CHAIN_SYMBOL,
    CHAIN_EXPLORER,
    RPC,
    INO_NFT,
    NFT_CONTRACT
} from '../../common/constant';

import {
    isInWeb3Browser,
    connect,
    switchNetwork,
    onAccountChange,
    ABI_ERC721
} from "../../common/dapp.js";
import Header from '../../components/common/Header.js';
import { Button } from 'react-bootstrap';
import { fetchText } from '../../common/rest.js';
import { useTranslation } from 'react-i18next';

import '../../i18n.js'

const ABI = [
    'function hatch(uint256 tokenId) public',
    'function amount() public view returns (uint256)',
    'function ownerOf(uint256 tokenId) public view returns (address)',
    'function tokenAvailableTime(uint256 tokenId) public view returns (uint256)',
    'function currentTokenId(address _addr) public view returns (uint256)',
    'function collected(uint256 tokenId) public view returns (bool)',
    'function collect(uint256 tokenId) public'
]

class _AppPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            meta: null,
            tokenId: null,
            token: null,
            isHatching: false,
            expireTime: null,
            metaPrice: null,

            chainId: null,
            schedule: '0',

            countdown: '--:--:--',

            timer: null,
        }
    }

    componentDidMount() {
        this.onLoad();
    }

    onLoad = async () => {
        const isInWeb3 = isInWeb3Browser();
        this.setState({ isInWeb3 });
        if (isInWeb3) {
            const options = await connect();
            this.setState({
                provider: options.provider,
                address: options.address,
                chainId: options.chainId,
            });

            onAccountChange({
                success: (accounts) => {
                    this.setState({
                        address: accounts[0]
                    });
                }
            });

            if (options.chainId != CHAIN_ID) {
                if (window.confirm("You are on the wrong network, do you want to switch to " + CHAIN_NAME + "?")) {
                    switchNetwork({
                        chainId: CHAIN_ID,
                        chainname: CHAIN_NAME,
                        symbol: CHAIN_SYMBOL,
                        decimals: 18,
                        rpcUrls: [RPC],
                        explorer: CHAIN_EXPLORER
                    }, () => {
                        this.onLoad();
                    });
                }
            } else {
                // 
                this.initData(options)
            }
        }
    }

    initData = async (options) => {
        // const contract = new ethers.Contract(META_APP2_CONTRACT, ABI, options.provider);
        // loading
        this.setState({ loading: true });
        await this._loadHatching(options);
        this.setState({ loading: false });
    }

    _canClaim = () => {
        return this.state.token && !this.state.collected && this.state.expireTime < Math.floor(Date.now() / 1000);
    }

    // 判断能否新增NFT
    _loadHatching = async (options) => {
        // 如果当前孵化槽里有token，判读一下这个token id是不是已经collected了， 如果没有collect，就显示孵化中， 如果已经collect了，就显示孵化完成
        const contract = new ethers.Contract(META_APP2_CONTRACT, ABI, options.provider);
        const tokenId = await contract.currentTokenId(options.address);
        console.log('tokenId', tokenId);
        if (tokenId > 0) {
            const collected = await contract.collected(tokenId);

            console.log('collected', collected);
            const expireTime = await contract.tokenAvailableTime(tokenId);
            console.log('expireTime', expireTime);

            // countdown
            const now = Math.floor(Date.now() / 1000);
            const countdown = expireTime.toString() - now;

            if (this.state.timer) {
                clearInterval(this.state.timer);
                this.setState({ timer: null });
            }

            if (countdown > 0) {
                const timer = setInterval(() => {
                    const now = Math.floor(Date.now() / 1000);
                    const countdown = expireTime.toString() - now;
                    if (countdown <= 0) {
                        clearInterval(timer);
                        this.onLoad();
                    } else {
                        let hours = Math.floor(countdown / 3600);
                        hours = hours < 10 ? '0' + hours : hours;
                        let minutes = Math.floor((countdown % 3600) / 60);
                        minutes = minutes < 10 ? '0' + minutes : minutes;
                        let seconds = countdown % 60;
                        seconds = seconds < 10 ? '0' + seconds : seconds;
                        this.setState({ countdown: `${hours}:${minutes}:${seconds}` });
                    }
                }, 1000);
                this.setState({ timer: timer });
            }

            this.setState({ isHatching: !collected, tokenId: tokenId, expireTime: expireTime, collected });

            const erc721contract = new ethers.Contract(tokenId < 10000000 ? NFT_CONTRACT : INO_NFT, ABI_ERC721, options.provider);
            const tokenUri = await erc721contract.tokenURI(tokenId);
            const token = await fetchText(tokenUri);
            this.setState({ token: JSON.parse(token) });
            console.log('token', token);

            // schedule = expireTime - now / 24 hours * 100
            // const now = Math.floor(Date.now() / 1000);
            const schedule = Math.min(100, 100 - Math.floor((expireTime.toString() - now) / 86400 * 100));

            this.setState({ schedule: schedule });

            return collected;
        } else {
            this.setState({ isHatching: false });
            return false;
        }
    }

    shortAddress = (address) => {
        if (address) {
            return address.substr(0, 8) + '...' + address.substr(-6);
        }
    }

    formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    doClaim = async () => {
        // 非常复杂的实现
        // 1、调用合约的collect方法
        // 2、后端写个程序监听CollectEvent事件，接收到事件后调用游戏接口的mint方法，并记录下来token id
        // 3、收到collect event，调用接口发放一个dragon nft到地址，结束。 
        // 所以这里只需要collect
        // if loading
        if (this.state.loading) {
            return;
        }
        try {
            this.setState({ loading: true });
            const options = await connect();
            const signer = await options.provider.getSigner();
            const contract = new ethers.Contract(META_APP2_CONTRACT, ABI, signer);
            const tx = await contract.collect(this.state.tokenId);
            await tx.wait(1);
            alert(this.props.t('App2InfoSuccess'));
            // this.onLoad();
            // reload
            // window.location.reload();
            // document.location.reload();
            window.location.href = '/';
        } catch (e) {
            console.log(e);
            alert('Claim failed: ' + (e.reason || e.message || e));
        } finally {
            this.setState({ loading: false });
        }
    }

    // render
    render() {
        // nft card style
        const cardStyle = {
            background: "rgba(255,255,255,0.01)",
            boxShadow: "inset 0px 0px 20px 0px rgba(255,255,255,0.2)",
            borderRadius: "12px",
            border: "1px solid rgba(255,255,255,0.21)",
            padding: "16px",
            marginTop: "16px",
        }

        // nft card body style
        const cardBody = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
        const {
            token,
            tokenId,
            collected
        } = this.state;
        return (
            <div style={{
                height: "100%",
                minHeight: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            }}>
                <div style={{
                    maxWidth: "600px",
                    width: "100%",
                    position: "relative",
                }}>
                    <Header address={this.state.address} title="App1" t={this.props.t} i18n={this.props.i18n} onClick={
                        () => {
                            this.onLoad();
                        }
                    } chainId={this.state.chainId} onShowRule={(e) => {
                        this.setState({
                            showRule: true
                        })
                    }} showInvite={false} />

                    <div style={{
                        marginTop: "360px",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                    }}>
                        <div style={cardStyle}>
                            <div style={cardBody}>
                                <div style={{
                                    width: "100%",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        marginTop: "1rem",
                                    }}>
                                        {token && tokenId < 10000000 && !collected ? <div style={{
                                            backgroundColor: 'rgba(255,255,255,0.2)',
                                            padding: '0.5rem',
                                            paddingLeft: '1rem',
                                            paddingRight: '1rem',
                                            borderRadius: '2rem',
                                        }}>
                                            {token.attributes[0].value}
                                        </div> : null}
                                        {token && tokenId && !collected ? <div style={{
                                            marginLeft: '1rem',
                                            backgroundColor: 'rgba(255,255,255,0.2)',
                                            padding: '0.5rem',
                                            paddingLeft: '1rem',
                                            paddingRight: '1rem',
                                            borderRadius: '2rem',
                                        }}>
                                            # {tokenId + ''}
                                        </div> : null}
                                        {token && tokenId < 10000000 && !collected ? <div style={{
                                            marginLeft: '1rem',
                                            backgroundColor: 'rgba(255,255,255,0.2)',
                                            padding: '0.5rem',
                                            paddingLeft: '1rem',
                                            paddingRight: '1rem',
                                            borderRadius: '2rem',
                                        }}>{token.attributes[1].value}</div> : null}
                                    </div>
                                    <div style={{ marginTop: '2rem', marginBottom: "1rem" }}>
                                        {/* a schedule bar with green color */}
                                        <div style={{
                                            width: `${this.state.schedule}%`,
                                            height: "10px",
                                            background: "#41ff76",
                                            borderRadius: "5px",
                                        }}></div>

                                    </div>
                                    {!collected ? <div>
                                        {this.props.t('App2Health')} {this.state.schedule} / 100
                                    </div> : <div>
                                        - / 100</div>}

                                    <div style={{
                                        textAlign: "center",
                                        marginTop: '2rem',
                                        marginBottom: "1rem"
                                    }}>
                                        <Button style={{
                                            background: 'linear-gradient( 90deg, #DE42CC 0%, #F2D32C 100%)',
                                            boxShadow: 'inset 0px -2px 6px 0px rgba(255,255,255,0.41)',
                                            borderRadius: '50px',
                                            width: '200px',
                                        }}
                                            size='lg'
                                            disabled={!this._canClaim()}
                                            onClick={async () => {
                                                if (!this._canClaim()) {
                                                    return;
                                                }
                                                this.doClaim();
                                            }}
                                        >
                                            {this._canClaim() ? this.props.t('App2ClaimButton') : this.state.countdown}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* dragon */}
                <div style={{
                    position: "absolute",
                    zIndex: 100,
                    left: 40,
                    top: 80,
                    right: 0,
                    textAlign: "center",
                }} onClick={() => {
                    if (!token || token && collected) {
                        window.location.href = '/addNFT'
                    }
                }}>
                    {token && !collected ? <img src={token.image.substr(0, token.image.length - 4) + '.png'} style={{
                        width: "220px",
                    }}></img> : <img src={require('../../assets/image/dragon.png')} style={{
                        width: "160px",
                    }} />}
                </div>
                {/* token-empty */}
                <div style={{
                    position: "absolute",
                    zIndex: -100,
                    left: '1rem',
                    top: 80,
                    textAlign: "center",
                    
                }}>
                    <img src={require(token && !collected ? '../../assets/image/token-full.png' : '../../assets/image/token-empty.png')} style={{
                        width: "60px",
                    }} />
                </div>
                {/* plus */}
                {token && !collected ? null : <div style={{
                    position: "absolute",
                    zIndex: -100,
                    left: 0,
                    top: 200,
                    right: 0,
                    textAlign: "center",
                }}>
                    <img src={require('../../assets/image/plus.png')} style={{
                        width: "30px",
                    }} />
                </div>}
                {/* platform */}
                <div style={{
                    position: "absolute",
                    zIndex: -100,
                    left: 0,
                    top: 230,
                    right: 0,
                    maxWidth: "600px",
                    // margin center
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                }}>
                    <img src={require('../../assets/image/platform.png')} style={{
                        width: "70%",
                    }} />
                </div>

                {/* bg image */}
                <div style={{
                    position: "absolute",
                    zIndex: -100,
                    left: 0,
                    top: 0,
                    right: 0,
                    textAlign: "center",
                }}>
                    <img src={require('../../assets/image/bg-top.png')} style={{
                        width: "80%",
                    }} />
                </div>
                {/* bg 2 */}
                <div style={{
                    position: "absolute",
                    zIndex: -200,
                    left: 0,
                    top: "0",
                    right: 0,
                    textAlign: "center",

                }}>
                    <img src={require('../../assets/image/bg2.png')} style={{
                        width: "100%",
                    }} />
                </div>
                {/* loading */}
                {
                    this.state.loading ? <div style={{
                        display: this.state.loading ? 'flex' : 'none',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 999,
                    }}>
                        Loading...
                    </div> : null
                }
            </div>
        );
    }


}

function AppPage() {
    const { t, i18n } = useTranslation();
    return (
        <_AppPage t={t} i18n={i18n} />
    );
}

export default AppPage;
import React from 'react';

import { ethers } from 'ethers';
import {
    NFT_CONTRACT,
    INO_NFT,
    META_APP2_CONTRACT,
    TOKEN_CONTRACT,
    CHAIN_ID,
    CHAIN_NAME,
    CHAIN_SYMBOL,
    CHAIN_EXPLORER,
    RPC
} from '../../common/constant';
import { ABI_ERC20, ABI_ERC721, connect, isInWeb3Browser, onAccountChange, switchNetwork } from '../../common/dapp';
import { useTranslation } from 'react-i18next';
import Header from '../../components/common/Header';
import { Button, Card } from 'react-bootstrap';

import { fetchText } from "../../common/rest.js";
import copy from 'copy-to-clipboard';
import { useSearchParams } from 'react-router-dom';

// i18n
import '../../i18n.js'

const ABI = [
    'function hatch(uint256 tokenId) public',
    'function amount() public view returns (uint256)',
    'function ownerOf(uint256 tokenId) public view returns (address)',
    'function tokenAvailableTime(uint256 tokenId) public view returns (uint256)',
    'function currentTokenId(address _addr) public view returns (uint256)',
    'function collected(uint256 tokenId) public view returns (bool)',
    'function collect(uint256 tokenId) public'
]

class _App3AddNFTPage extends React.Component {

    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            isHatching: false,

            nfts: [],
            nftTab: 0,

            step: 0,
            steps: [t('App2AddTab1'), t('App2AddTab2'), t('App2AddTab3')],

            nftApproved: false,
            metaApproved: false,
            metaPrice: null,

            selectTokenId: null,
            selectToken: null,
            chainId: null,

            loading: false,

            showSuccessInfo: false,
            txHash: null,
        }
    }

    componentDidMount() {
        this.onLoad();
    }

    onLoad = async () => {
        const isInWeb3 = isInWeb3Browser();
        this.setState({ isInWeb3 });
        if (isInWeb3) {
            const options = await connect();
            this.setState({
                provider: options.provider,
                address: options.address,
                chainId: options.chainId,
            });

            onAccountChange({
                success: (accounts) => {
                    this.setState({
                        address: accounts[0]
                    });
                }
            });
            if (options.chainId != CHAIN_ID) {
                if (window.confirm("You are on the wrong network, do you want to switch to " + CHAIN_NAME + "?")) {
                    switchNetwork({
                        chainId: CHAIN_ID,
                        chainname: CHAIN_NAME,
                        symbol: CHAIN_SYMBOL,
                        decimals: 18,
                        rpcUrls: [RPC],
                        explorer: CHAIN_EXPLORER
                    }, () => {
                        this.onLoad();
                    });
                }
            } else {
                // 
                this.initData(options)
            }
        }
    }

    initData = async (options) => {
        await this._loadHatching(options);
        this._loadNFTs(options);
        this._loadMetaPrice(options);
        const metaApproved = await this._getMetaAllowance(options);
        this.setState({ metaApproved });
    }

    // 判断能否新增NFT
    _loadHatching = async (options) => {
        // 如果当前孵化槽里有token，判读一下这个token id是不是已经collected了， 如果没有collect，就显示孵化中， 如果已经collect了，就显示孵化完成
        const contract = new ethers.Contract(META_APP2_CONTRACT, ABI, options.provider);
        const tokenId = await contract.currentTokenId(options.address);
        if (tokenId > 0) {
            const collected = await contract.collected(tokenId);
            this.setState({ isHatching: !collected });
            if (!collected) {
                alert(this.props.t('App2ErrorExists'));
                return;
            }
            return collected;
        } else {
            this.setState({ isHatching: false });
            return false;
        }
    }

    _getMetaAllowance = async (options) => {
        const { provider, address } = options;
        const contract = new ethers.Contract(TOKEN_CONTRACT, ABI_ERC20, provider);
        const allowance = await contract.allowance(address, META_APP2_CONTRACT);
        return allowance >= this.state.metaPrice;
    }

    _loadMetaPrice = async (options) => {
        const { provider } = options;
        const contract = new ethers.Contract(META_APP2_CONTRACT, ABI, provider);
        const metaPrice = await contract.amount();
        this.setState({ metaPrice });
        console.log('metaPrice', metaPrice);
    }

    _doApproveMeta = async (options) => {
        // loading
        this.setState({ loading: true });

        try {
            const { provider, address } = options;
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(TOKEN_CONTRACT, ABI_ERC20, signer);
            const tx = await contract.approve(META_APP2_CONTRACT, this.state.metaPrice);
            await tx.wait(1);

            const allowance = await contract.allowance(address, META_APP2_CONTRACT);
            this.setState({ metaApproved: allowance >= this.state.metaPrice });

            if (allowance >= this.state.metaPrice) {
                // next
                this.setState({ step: 2 });
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    _getNFTApproved = async (options) => {
        const { provider, address } = options;
        const contract = new ethers.Contract(NFT_CONTRACT, ABI_ERC721, provider);
        const approved = await contract.getApproved(this.state.selectTokenId);
        return approved === META_APP2_CONTRACT;
    }

    _doApproveNFT = async (options) => {
        // start loading
        this.setState({ loading: true });

        try {
            const { provider, address } = options;
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(NFT_CONTRACT, ABI_ERC721, signer);
            const tx = await contract.approve(META_APP2_CONTRACT, this.state.selectTokenId);
            await tx.wait(1);

            const approved = await contract.getApproved(this.state.selectTokenId);
            this.setState({ nftApproved: approved === META_APP2_CONTRACT });
            if (approved) {
                // next
                this.setState({ step: 1 });
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    _doSubmit = async (options) => {
        const { provider, address } = options;
        this.setState({ loading: true });
        try {
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(META_APP2_CONTRACT, ABI, signer);
            const tx = await contract.hatch(this.state.selectTokenId);

            this.setState({ txHash: tx.hash, showSuccessInfo: true });
        } catch (e) {
            alert('Submit fail: ' + (e.reason || e.message || e));
            console.error(e);
        } finally {
            this.setState({ loading: false });
        }

    }

    shortAddress = (address) => {
        if (address) {
            return address.substr(0, 8) + '...' + address.substr(-6);
        }
    }


    formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    _loadNFTs = async (options) => {
        this._loadNFTsType(options, this.state.nftTab)
    }

    _loadNFTsType = async (options, type) => {
        // if loading
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        try {
            const { provider, address } = options
            const contract = new ethers.Contract(type == 0 ? NFT_CONTRACT : INO_NFT, ABI_ERC721, provider);
            const nftCount = await contract.balanceOf(address);
            console.log('nftCount', nftCount);
            const nfts = [];
            for (let i = 0; i < nftCount; i++) {
                const tokenId = await contract.tokenOfOwnerByIndex(address, i);
                const tokenUri = await contract.tokenURI(tokenId);
                const resp = await fetchText(tokenUri);
                const meta = JSON.parse(resp);
                if (tokenId > 10000000) {
                    if (!meta.generation) {
                        continue;
                    }
                }

                nfts.push({
                    tokenId,
                    ...meta
                });

                const _nft = {
                    tokenId,
                    ...meta
                }
            }
            console.log('nfts', nfts);
            this.setState({ nfts });

            if (!this.state.selectTokenId && nfts.length > 0) {
                const _nft = nfts[0];
                this.setState({
                    selectTokenId: _nft.tokenId,
                    loading: true,
                    selectToken: _nft,
                });
                const options = await connect();
                const nftApproved = await this._getNFTApproved(options)
                this.setState({ nftApproved, loading: false });
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { chainId } = this.state;

        // nft card style
        const cardStyle = {
            background: "rgba(255,255,255,0.01)",
            boxShadow: "inset 0px 0px 20px 0px rgba(255,255,255,0.2)",
            borderRadius: "12px",
            border: "1px solid rgba(255,255,255,0.21)",
            padding: "16px",
            marginTop: "16px",
        }

        // nft card body style
        const cardBody = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }

        const t = this.props.t;

        return (
            <div style={{
                height: "100%",
                minHeight: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            }}>
                <div style={{
                    maxWidth: "600px",
                    width: "100%",
                }}>
                    <Header address={this.state.address} title="App1" t={this.props.t} i18n={this.props.i18n} onClick={
                        () => {
                            this.onLoad();
                        }
                    } chainId={chainId} onShowRule={(e) => { }} showInvite={false} />
                    <div style={{
                        margin: '1rem'
                    }}>
                        {/* steps */}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            height: '3rem',
                            fontSize: '1.5rem',
                            color: '#0B57FF',
                            fontWeight: 'bold',
                        }}>
                            {
                                this.state.steps.map((step, index) => {
                                    return (
                                        <div key={index} style={{
                                            display: 'flex',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                            height: '3rem',
                                            fontSize: '1rem',
                                            color: this.state.step === index ? '#fff' : 'rgba(255,255,255,0.4)',
                                            fontWeight: 'bold',
                                            width: '33.3%',
                                            // borderBottom: this.state.step === index ? '2px solid #0B57FF' : '2px solid #ffffff',

                                        }}>
                                            {step}
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {/* end of steps */}

                        {this.state.step === 0 ? <div>
                            <div style={{
                                display: 'flex',
                                fontSize: '1.2rem',
                                marginTop: '1.2rem',
                            }}>
                                {t('App2LabelSelectToken')}
                            </div>
                            {/* nft tabs */}
                            {this.props.eggs == 1 ? <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '3rem',
                                fontSize: '1.5rem',
                                color: '#0B57FF',
                                fontWeight: 'bold',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                    height: '3rem',
                                    fontSize: '1rem',
                                    color: this.state.nftTab === 0 ? '#fff' : 'rgba(255,255,255,0.4)',
                                    fontWeight: 'bold',
                                    width: '50%',
                                    borderBottom: this.state.nftTab === 0 ? '2px solid #fff' : '0px',
                                }} onClick={async () => {
                                    this.setState({ nftTab: 0 })
                                    const options = await connect();
                                    this._loadNFTs(options);
                                }}>
                                    META NFTs
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                    height: '3rem',
                                    fontSize: '1rem',
                                    color: this.state.nftTab === 1 ? '#fff' : 'rgba(255,255,255,0.4)',
                                    fontWeight: 'bold',
                                    width: '50%',
                                    borderBottom: this.state.nftTab === 1 ? '2px solid #fff' : '0px',
                                }} onClick={async () => {
                                    this.setState({ nftTab: 1 })
                                    const options = await connect();
                                    this._loadNFTsType(options, 1);
                                }}>
                                    Dragon Eggs
                                </div>
                            </div> : null}
                            {/* end of nft tabs */}

                            {/* start of nfts list */}
                            <div style={{
                                marginBottom: '2rem',
                                marginTop: '1rem',
                            }}>
                                {/* iterator nfts */}
                                {
                                    this.state.nfts.map((nft, index) => {
                                        return (
                                            <div key={index} style={cardStyle} onClick={async (e) => {
                                                this.setState({
                                                    selectTokenId: nft.tokenId,
                                                    loading: true,
                                                    selectToken: nft,
                                                });
                                                const options = await connect();
                                                const nftApproved = await this._getNFTApproved(options)
                                                this.setState({ nftApproved, loading: false });
                                            }}>
                                                <div style={cardBody}>
                                                    <div style={{
                                                        display: 'flex',

                                                    }}>
                                                        <img src={nft.image} style={{
                                                            width: '80px',
                                                            height: '80px',
                                                            borderRadius: '8px'
                                                        }}></img>

                                                        <div style={{
                                                            marginLeft: '1rem',
                                                        }}>
                                                            <div>
                                                                {nft.name}
                                                            </div>
                                                            {/* iterator nft.attributes */}
                                                            <div style={{
                                                                display: 'flex',
                                                            }}>
                                                                {
                                                                    nft.attributes.map((attr, index) => {
                                                                        if (attr.trait_type === 'r1' || attr.trait_type === 'r2') {
                                                                            return null;
                                                                        } else {
                                                                            return (
                                                                                <div key={index} style={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    fontSize: '0.8rem',
                                                                                    color: '#fff',
                                                                                    background: 'rgba(0,0,0,0.5)',
                                                                                    borderRadius: '4px',
                                                                                    padding: '0.2rem',
                                                                                    margin: '0.2rem',
                                                                                }}>
                                                                                    {attr.value}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                            {/* end of iterator nft.attributes */}
                                                        </div>
                                                    </div>

                                                    <div style={{ width: '80px', textAlign: 'right' }}>
                                                        {nft.tokenId === this.state.selectTokenId ? <div>
                                                            <img alt='' src={require('../../assets/icons/check.png')} style={{
                                                                width: '24px',
                                                                height: '24px',
                                                            }} />
                                                        </div> : <div></div>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {/* end of iterator nfts */}
                            </div>
                            <div style={{
                                position: 'fixed',
                                bottom: '1.2rem',
                                left: 0,
                                right: 0,
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                            }}>
                                <Button variant="warning" style={{
                                    width: '100%',
                                    height: '3rem',
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                }} onClick={async (e) => {
                                    if (this.state.isHatching) {
                                        alert(t('App2ErrorExists'));
                                        return;
                                    }
                                    if (this.state.selectTokenId) {
                                        if (this.state.nftApproved) {
                                            this.setState({ step: 1 });
                                        } else {
                                            const options = await connect();
                                            this._doApproveNFT(options)
                                        }
                                    } else {
                                        alert(t('App2LabelSelectToken'));
                                    }
                                }}>
                                    {this.state.nftApproved ? t('App2ButtonNext') : t('App2ButtonApproveNFT')}
                                </Button>
                            </div>
                        </div> : null}
                        {/* end of nfts list (end of step 0) */}

                        {/* start of step 1 , auth meta token */}
                        {this.state.step === 1 ? <div>
                            <div style={{
                                fontSize: '1.2rem',
                                // center
                                justifyContent: 'center',
                                alignItems: 'center',
                                // margin top
                                marginTop: '30%',
                                textAlign: 'center',
                            }}>
                                <div>
                                    <img src={require('../../assets/image/token-full.png')} style={{
                                        width: '200px',
                                        height: '200px',
                                    }}></img>
                                </div>
                                {this.state.metaPrice ? <div style={{
                                    marginTop: '2rem',
                                    // background: 'linear - gradient(270deg, rgba(255, 255, 255, 0) 0 %, #FFFFFF 50 %, rgba(255, 255, 255, 0) 100 %)',
                                    width: '80 %',
                                }}>
                                    {t('App2AddMetaTip', {
                                        meta: ethers.formatEther(this.state.metaPrice)
                                    })}
                                    {/* 消耗 {ethers.formatEther(this.state.metaPrice) + ''} META, 24小时完成 */}
                                </div> : null}
                            </div>
                            <div style={{
                                position: 'fixed',
                                bottom: '1.2rem',
                                left: 0,
                                right: 0,
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                            }}>
                                <Button variant="warning" style={{
                                    width: '100%',
                                    height: '3rem',
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                }} onClick={async (e) => {
                                    console.log('metaApproved', this.state.metaApproved)
                                    if (this.state.metaApproved) {
                                        this.setState({ step: 2 });
                                    } else {
                                        const options = await connect();
                                        this._doApproveMeta(options)
                                    }
                                }}>
                                    {this.state.metaApproved ? t('App2ButtonNext') : t('App2ButtonApproveMETA')}
                                </Button>
                            </div>
                        </div> : null}
                        {/* end of step 1 */}

                        {/* start of step 2 */}
                        {this.state.step === 2 ? <div>
                            {/* begin of nft */}
                            {this.state.selectToken ? <div style={cardStyle}>
                                <div style={cardBody}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={this.state.selectToken.image} style={{
                                            width: '80px',
                                            height: '80px',
                                            borderRadius: '8px'
                                        }}></img>
                                        <div style={{
                                            marginLeft: '1rem',
                                        }}>
                                            {this.state.selectToken.name}
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                            {/* end of nft */}
                            <div style={cardStyle}>
                                <div style={cardBody}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={require('../../assets/image/tokenicon1.png')} style={{
                                            width: '80px',
                                            height: '80px',
                                            borderRadius: '8px'
                                        }}></img>
                                        {this.state.metaPrice ? <div style={{
                                            marginLeft: '1rem',
                                        }}>
                                            {ethers.formatEther(this.state.metaPrice) + ''} META
                                        </div> : null}
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                position: 'fixed',
                                bottom: '1.2rem',
                                left: 0,
                                right: 0,
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                            }}>
                                <Button variant="warning" style={{
                                    width: '100%',
                                    height: '3rem',
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                }} onClick={async (e) => {
                                    if (window.confirm(t('App2AddHatchingTip'))) {
                                        const options = await connect();
                                        this._doSubmit(options);
                                    }
                                }}>
                                    {t('App2ConfirmToHatching')}
                                </Button>
                            </div>
                        </div> : null}
                        {/* end of step 2 */}
                    </div>
                </div>


                {/* loading */}
                {
                    this.state.loading ? <div style={{
                        display: this.state.loading ? 'flex' : 'none',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 999,
                    }}>
                        Loading...
                    </div> : null
                }
                {/* end of loading */}

                {/* begin of success */}
                {this.state.showSuccessInfo ? <div style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "999",
                }} onClick={(e) => {
                    this.setState({
                        showSuccessInfo: false
                    });

                }}>
                    <div style={{
                        width: "80%",
                        backgroundColor: "white",
                        padding: "1rem",
                        borderRadius: "1.5rem",
                        position: "relative",
                    }}>
                        <div style={{
                            textAlign: "center"
                            , marginBottom: "2rem"
                            , marginTop: "2rem"
                        }}>
                            <img src={require('../../assets/icons/waiting.png')} style={{
                                width: "3rem",
                                height: "3rem",
                            }} onClick={(e) => {
                                this.setState({
                                    showSuccessInfo: false
                                });
                            }} />
                        </div>
                        <div style={{
                            textAlign: "center",
                            color: "black",
                            marginBottom: "2rem"
                        }}>
                            <div>{t('App2TxSubmit')}</div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                            }}>
                                Hash: {this.shortAddress(this.state.txHash)}
                                <img src={require('../../assets/icons/copy.png')} style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    marginLeft: "0.5rem",
                                }} onClick={(e) => {
                                    copy(this.state.txHash);
                                }} />
                            </div>
                            <span style={{
                                color: "blue",
                                textDecoration: "underline",
                            }} onClick={(e) => {
                                document.location.href = CHAIN_EXPLORER + '/tx/' + this.state.txHash;
                            }}>{t('ResultMessage2')}</span>
                        </div>
                        <div>
                            <center style={{
                                fontWeight: "bold",
                                fontSize: "1.2rem",
                                color: "black",
                            }} onClick={(e) => {
                                this.setState({
                                    showSuccessInfo: false
                                });
                                // reload
                                window.location.href = '/'
                            }}>
                                OK
                            </center>
                        </div>
                    </div>
                </div> : null}
                {/* end of success */}
            </div >
        );
    }



}

function App3AddNFTPage() {
    const { t, i18n } = useTranslation();
    // 读取参数
    const [params] = useSearchParams();
    const eggs = params.get('eggs') || 0;
    console.log('eggs', eggs);
    return (
        <_App3AddNFTPage t={t} i18n={i18n} eggs={eggs} />
    );
}

export default App3AddNFTPage;
// export const RPC = "https://mainnet.infura.io/v3/"
// export const CHAIN_ID = 1
// export const CHAIN_NAME = "ETH"
// export const CHAIN_SYMBOL = "ETH"
// export const CHAIN_BLOCK_TIME = 15
// export const CHAIN_EXPLORER = "https://etherscan.io"
// export const EGGX_CONTRACT = "0xe2f95ee8B72fFed59bC4D2F35b1d19b909A6e6b3"
// export const EGGX_APP1_CONTRACT = "0xFb35DE57B117FA770761C1A344784075745F84F9"
// export const EGGX_CONTRACT_V2 = "0x5a1d5c08C3e16284F699b8bC39f069dB69CA3A4a"


// holesky
// export const RPC = "https://ethereum-holesky.publicnode.com"
// export const CHAIN_ID = 17000
// export const CHAIN_NAME = "Holesky"
// export const CHAIN_SYMBOL = "ETH"
// export const CHAIN_BLOCK_TIME = 15
// export const CHAIN_EXPLORER = "https://holesky.etherscan.io"
// export const EGGX_APP1_CONTRACT = "0x17fF7b7AC5B6E0CEA38FD5A5975112A1425ca8A5"

// export const EGGX_CONTRACT = "0x554b190869Fc6f257064328fbaf3C6581A96c892"
// export const EGGX_CONTRACT_V2 = "0x1Da5665855CadfC2Db959194e6035d7E51700824"

// BSC
// export const RPC = "https://bsc-dataseed1.binance.org/"
// export const CHAIN_ID = 56
// export const CHAIN_NAME = "BSC"
// export const CHAIN_SYMBOL = "BNB"
// export const CHAIN_BLOCK_TIME = 3
// export const CHAIN_EXPLORER = "https://bscscan.com"
// export const EGGX_CONTRACT = ""
// export const NFT_CONTRACT = "0x336a7675a863C12F7B49061B3ecb9E54bE5e2120"
// export const EGGX_APP1_CONTRACT = "0x77B056eB208f8cE5E010eE7dc1A6976Ac8c9536a"
// export const EGGX_CONTRACT_V2 = ""
// export const META_APP2_CONTRACT = ""
// export const INO_NFT = "0xf6163b4b84DF1A7AF59251d95c6e6B77FDEB5Ee5"
// export const TOKEN_CONTRACT = "0xEF1f39d8391cdDcaee62b8b383cB992F46a6ce4f"


// BSC Testnet

export const RPC = "https://bsc-testnet-rpc.publicnode.com"
export const CHAIN_ID = 97
export const CHAIN_NAME = "BSC Testnet"
export const CHAIN_SYMBOL = "tBNB"
export const CHAIN_BLOCK_TIME = 3
export const CHAIN_EXPLORER = "https://testnet.bscscan.com/"
export const EGGX_CONTRACT = ""
export const NFT_CONTRACT = "0x8b251AfdfC9c59d4872dDFF36985213B9b40f7e5"
export const EGGX_APP1_CONTRACT = "0xfc662e31067c75bA2A003E92946f41Ecee313b36"
export const EGGX_CONTRACT_V2 = ""
export const META_APP2_CONTRACT = "0xA79A598934F28D15C13017F9B37c86e82F7618de"
export const INO_NFT = "0xA4a095EFa3fAAa0acF55dA53457f540091B2121A"
export const TOKEN_CONTRACT = "0xAe3Da0529Dd4ce63f3c38919f8B4BAFc1893096E"